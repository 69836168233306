/* Style for Quill editor */

/* #root {
  margin: 1rem 4rem;
} */

#root .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  border: 1px solid #f0f0f0;
}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
  display: block;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border: 1px solid #f0f0f0;
}

#root .ql-editor {
  min-height: 20px;
}
